<mat-toolbar color="primary">
  <button mat-icon-button 
    class="example-icon" 
    aria-label="Example icon-button with menu icon"
    (click)="drawer.toggle()"
  >
    <mat-icon>menu</mat-icon>
  </button>
  <a routerLink="/" style="margin-right: 14px;"><img src="assets/cloudverve_logo.png" class="cloudverve_logo"></a>
  <span class="example-spacer"></span>
  <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
    <mat-icon>favorite</mat-icon>
  </button>
  <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
    <mat-icon>share</mat-icon>
  </button>
  <a href="/.auth/login/aad?post_login_redirect_uri={{redirect}}">
    <button mat-icon-button class="example-icon favorite-icon" *ngIf="!this.userInfo">
      <mat-icon>login</mat-icon>
    </button>
  </a>
  <span *ngIf="this.userName">{{ this.userName }}</span>
  <a href="/.auth/logout">
    <button mat-icon-button class="example-icon favorite-icon" *ngIf="this.userInfo">
      <mat-icon>logout</mat-icon>
    </button>
  </a>
</mat-toolbar>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item routerLink="/dashboard"><i class="material-icons">info</i>My Dashboard</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    
    <!-- Add Content Here -->
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>