import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { InsightsService } from './insights.service';



@NgModule({
  providers: [
    InsightsService,
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService
    }
  ],
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class InsightsModule { 
  constructor(private insights: InsightsService) {}
}
