import { Component } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { UserInfo } from './user-info';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'cloudverve-frontend';

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
  );

  isPrincipalAdmin: boolean = false;
  userInfo: UserInfo | undefined;
  userName: string | undefined;

  redirect = window.location.pathname;

  constructor(private breakpointObserver: BreakpointObserver) {
  }

  async ngOnInit() {
    this.userInfo = await this.getUserInfo();
    this.userName = this.userInfo?.claims.find(x => x.typ === 'name')?.value;
  }

  async getUserInfo() {
    try {
      const response = await fetch('/.auth/me');
      const payload = await response.json();
      const { clientPrincipal } = payload;
      return clientPrincipal;
    } catch (error) {
      console.error('No profile could be found');
      return undefined;
    }
  }
}
